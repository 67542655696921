import * as types from '../actions/actionTypes';

const initialState = {
  onAlert: false,
  severity: '', // success | warning | error | info
  text: '',
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_ALERT:
      return {
        ...state,
        onAlert: true,
        severity: action.severity,
        text: action.text,
      };
    case types.OFF_ALERT:
      return {
        ...state,
        onAlert: false,
        severity: '',
        text: '',
      };
    default:
      return state;
  }
};

export default alert;
