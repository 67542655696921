// local storage
export const isLoggedIn = () => Boolean(localStorage.token);
export const getToken = () => localStorage.token;

export const saveItem = (key, value) => localStorage.setItem(key, value);
export const deleteItem = (key) => localStorage.removeItem(key);

export const clearStorage = () => localStorage.clear();

export const saveObjData = (key, value) => localStorage.setItem(key, JSON.stringify(value));
export const getObjData = (key) => JSON.parse(localStorage.getItem(key));
