/* eslint-disable no-param-reassign */
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_HOST } from '../../helpers/config';
import { getToken, clearStorage } from '../../helpers/storage';
import StrapiMessageEn from '../../helpers/strapiMessageEn.json';
import StrapiMessageKo from '../../helpers/strapiMessageKo.json';
import * as types from '../actions/actionTypes';

const Axios = axios.create({
  baseURL: API_HOST,
  timeout: 9000,
});

axios.defaults.validateStatus = (status) => status < 400;

const translateStrapiMsg = (msg) => {
  const EN = StrapiMessageEn;
  const KO = StrapiMessageKo;
  const id = Object.keys(EN).filter((key) => EN[key] === msg)[0];

  if (!id) return msg;
  return KO[id];
};

export const interceptors = (store) => {
  Axios.interceptors.request.use(
    (config) => {
      if (config.url === '/auth/local') {
        return config;
      } else {
        const token = getToken();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          config.headers['content-type'] = 'application/json';

          if (config.params) {
            // convert to snake_case
            config.params = decamelizeKeys(config.params);
          }
          if (config.data) {
            config.data = decamelizeKeys(config.data);
          }

          return config;
        }
      }
    },
    (error) => Promise.reject(error)
  );

  const onFulfilled = (response) => {
    if (response.data) {
      // convert to camelCase
      response.data = camelizeKeys(response.data);
    }

    return response;
  };

  const onRejected = (error) => {
    if (error.response) {
      const { status, data } = error.response;

      let errorMsg;

      if (typeof data === 'string') {
        // 404 error
        errorMsg = data;
      } else if (Array.isArray(data.data)) {
        // 400 error
        errorMsg = data.data[0].messages[0].message;
      } else if (typeof error.message === 'string') {
        errorMsg = data.message;
      } else {
        errorMsg = '이슈가 있습니다. 다시 시도해주세요.';
      }

      if (status === 401 || status === 403) {
        // 401 or 403 -> login 화면
        // clearStorage();
        // window.location.href = '/login';

        // alert
        store.dispatch({
          type: types.ON_ALERT,
          severity: 'error',
          text: StrapiMessageKo['Auth.form.error.invalid'],
        });
      } else {
        // api 호출 종료 ( isLoading 종료 )
        store.dispatch({
          type: types.FAILURE,
          error: '',
          status: null,
        });

        // alert
        store.dispatch({
          type: types.ON_ALERT,
          severity: 'error',
          text: translateStrapiMsg(errorMsg),
        });

        // server error -> error 화면
        throw new Error('Error');
      }
    } else {
      // api 호출 종료 ( isLoading 종료 )
      store.dispatch({
        type: types.FAILURE,
        error: '',
        status: null,
      });

      // alert
      store.dispatch({
        type: types.ON_ALERT,
        severity: 'error',
        text: '네트워크에 문제가 있습니다. 다시 시도해 주세요.',
      });

      // network error -> error 화면
      throw new Error('Network Error');
    }

    return Promise.reject(error);
  };

  Axios.interceptors.response.use(onFulfilled, onRejected);
};

export default Axios;
