import * as types from '../actions/actionTypes';

const initialState = {
  prevPath: [],
};

const route = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PATH:
      return {
        prevPath: [...state.prevPath, action.path],
      };
    default:
      return state;
  }
};

export default route;
