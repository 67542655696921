import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Alert from './components/shared/alert';
import { updatePath } from './store/actions/routeAction';

const App = ({ route, alert, updatePath, children }) => {
  const location = useLocation();

  useEffect(() => {
    if (!route.prevPath.length || (route.prevPath.length && route.prevPath !== location.pathname)) {
      updatePath(location.pathname);
    }
  }, [location.pathname]);

  return (
    <>
      {Boolean(alert.onAlert) && alert.text.length && <Alert />}
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  route: state.route,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  updatePath: (path) => dispatch(updatePath(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
