import React from 'react';

const Warning = ({ name, width, height, opacity, viewBox, fillOuter, fillInner }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		className={`svg-icon icon-${name}`}
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="none" fillRule="evenodd">
			<path
				fill={fillOuter}
				opacity={opacity}
				d="M0.146823422,1.30389658 L9.58438756,15.7443881 C9.80715754,16.085204 10.1928276,16.085204 10.4155976,15.7443881 L19.8531617,1.30389658 C20.1806103,0.80286262 19.9247208,-7.10542736e-14 19.4375192,-7.10542736e-14 L0.562465886,-7.10542736e-14 C0.0753392126,-7.10542736e-14 -0.180625211,0.80286262 0.146823422,1.30389658 L-0.596679688,-7.10542736e-14"
				transform="matrix(1 0 0 -1 0 16)"
			/>
			<path
				fill={fillInner}
				d="M11,6.5 L10.7115385,10.5639777 L9.78846154,10.5639777 L9.5,6.5 L11,6.5 Z M9.53296703,11.7823366 C9.53296703,11.3817803 9.84615385,11.0563282 10.2417582,11.0563282 C10.6373626,11.0563282 10.9587912,11.3817803 10.9587912,11.7823366 C10.9587912,12.174548 10.6373626,12.5 10.2417582,12.5 C9.84615385,12.5 9.53296703,12.174548 9.53296703,11.7823366 Z"
			/>
		</g>
	</svg>
);
export default Warning;
