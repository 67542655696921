import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import theme from '../styles/theme';
import NotFound from './notFound';
import Button from '../components/shared/button';

const rootStyle = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.common.black,
  background: theme.palette.common.white,
  zIndex: 50,
};

const innerStyles = {
  width: '60%',
  marginBottom: '10%',
};

const BadRequest = ({ status }) => [
  <h1 key="be-1" style={{ color: theme.palette.primary.main }}>
    {status ? <span>{status}.</span> : null} This page isn't working.
  </h1>,
  <h2 key="be-2">Your client has issued a malformed or illegal request.</h2>,
  <h2 key="be-3">If the problem continues, contact the engineer department.</h2>,
];

const SystemError = ({ status }) => [
  <h1 key="se-1" style={{ color: theme.palette.primary.main }}>
    {status ? <span>{status}.</span> : null} Something went wrong.
  </h1>,
  <h2 key="se-2">Oops! We're experiencing an internal Server Error.</h2>,
  <h2 key="se-3">Please try again later.</h2>,
];

export const ErrorComponents = ({ status, route: { prevPath }, history }) => {
  const onRedirect = () => {
    // history.goBack is not working..
    history.push(prevPath[prevPath.length - 2]);

    window.location.reload();
  };

  return (
    <>
      {status === 404 ? (
        <NotFound />
      ) : (
        <div style={rootStyle}>
          <div style={innerStyles}>
            {status === 400 ? <BadRequest status={status} /> : <SystemError status={status} />}
            <Button name="Go Back" styles={{ margin: '20px 0px 0px' }} onClick={onRedirect} />
          </div>
        </div>
      )}
    </>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`error : ${error.name}, message: ${error.message || 'null'}`);
      console.log(`info : ${JSON.stringify(info)}`);
    }
  }

  render() {
    if (this.state.hasError || (this.props.asyncApi.status && this.props.asyncApi.status !== 200)) {
      return (
        <ErrorComponents status={this.props.asyncApi.status} route={this.props.route} history={this.props.history} />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  route: state.route,
  asyncApi: state.asyncApi,
});

export default connect(mapStateToProps, null)(withRouter(ErrorBoundary));
