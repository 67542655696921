import React from 'react';

const KsdLogo = ({ name, width, height, viewBox }) => (
  <svg
    width={width || '108'}
    height={height || '20'}
    viewBox={viewBox || '0 0 108 20'}
    className={`svg-icon icon-${name}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91.9111 20H86.2732C85.4907 20 85.1 19.0461 85.655 18.4905L93.2051 10.9333L89.2806 7.00501C88.5906 6.31438 88.5906 5.19014 89.2806 4.49951L93.2628 0.513497C93.9468 -0.171167 95.0514 -0.171165 95.7354 0.513499L107.482 12.271C108.172 12.9616 108.172 14.0859 107.482 14.7765L103.499 18.7625C102.815 19.4472 101.711 19.4472 101.027 18.7625L97.0871 14.819L91.9111 20Z"
      fill="#A03B54"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.974207 1.86047C0.436167 1.86047 0 2.27694 0 2.7907V14.2918C0 14.8055 0.436167 15.222 0.974207 15.222H9.33455C9.87259 15.222 10.3088 14.8055 10.3088 14.2918V2.7907C10.3088 2.27694 9.87259 1.86047 9.33455 1.86047H0.974207ZM3.13953 4.52431H6.90698V12.5581H3.13953V4.52431Z"
      fill="#A03B54"
    />
    <path
      d="M17.9731 10.1269C18.5112 10.1269 18.9474 9.71037 18.9474 9.19662V8.28753C18.9474 7.77377 18.5112 7.35729 17.9731 7.35729H15.9562V0.972515C15.9562 0.458762 15.52 0.0422828 14.982 0.0422828H13.4968C12.9588 0.0422828 12.5226 0.458761 12.5226 0.972514V19.0275C12.5226 19.5412 12.9588 19.9577 13.4968 19.9577H14.982C15.52 19.9577 15.9562 19.5412 15.9562 19.0275V10.1269H17.9731Z"
      fill="#A03B54"
    />
    <path
      d="M37.4282 0.930233C37.4282 0.416479 36.992 0 36.454 0H34.9688C34.4307 0 33.9946 0.41648 33.9946 0.930233V19.0698C33.9946 19.5835 34.4307 20 34.9688 20H36.454C36.992 20 37.4282 19.5835 37.4282 19.0698V0.930233Z"
      fill="#A03B54"
    />
    <path
      d="M58.2031 10.0083C58.4629 9.45031 58.0971 8.81041 57.48 8.65032C53.6842 7.66564 51.7125 5.10605 51.7125 2.72727V2.0296C51.7125 1.51584 51.2763 1.09937 50.7383 1.09937H49.0522C48.5141 1.09937 48.078 1.51584 48.078 2.0296V2.72727C48.078 5.04783 46.0897 7.65138 42.3298 8.64669C41.7141 8.80968 41.3491 9.44928 41.6088 10.0067L42.0719 11.0008C42.2541 11.3918 42.6935 11.6172 43.1297 11.5187C46.2308 10.8186 48.5556 9.1823 49.8833 7.06881C51.2141 9.20124 53.5607 10.8231 56.6837 11.5202C57.1199 11.6175 57.5588 11.3921 57.7407 11.0013L58.2031 10.0083Z"
      fill="#A03B54"
    />
    <path
      d="M59.5475 15.8774C59.5475 15.3636 59.1113 14.9471 58.5733 14.9471H51.6232V11.5222C51.6232 11.0084 51.187 10.592 50.649 10.592H49.1638C48.6258 10.592 48.1896 11.0084 48.1896 11.5222V14.9471H41.4181C40.88 14.9471 40.4439 15.3636 40.4439 15.8774V16.723C40.4439 17.2368 40.88 17.6533 41.4181 17.6533H58.5733C59.1113 17.6533 59.5475 17.2368 59.5475 16.723V15.8774Z"
      fill="#A03B54"
    />
    <path
      d="M72.9402 9.88786C73.2495 9.36652 72.9648 8.70975 72.3954 8.45804C70.1777 7.4777 69.1587 5.7707 69.0427 4.01691H72.0763C72.6143 4.01691 73.0505 3.60043 73.0505 3.08668V2.24101C73.0505 1.72726 72.6143 1.31078 72.0763 1.31078H62.4213C61.8832 1.31078 61.4471 1.72726 61.4471 2.24102V3.08668C61.4471 3.60043 61.8832 4.01691 62.4213 4.01691H65.523C65.4087 5.98727 64.3128 7.95654 61.9702 9.08498C61.4156 9.3521 61.1537 10.0077 61.4702 10.518L62.0833 11.5065C62.315 11.8802 62.7894 12.0561 63.2091 11.8887C65.134 11.1208 66.512 9.81175 67.378 8.21455C68.2176 9.52063 69.5134 10.5882 71.269 11.2336C71.6916 11.389 72.1594 11.2042 72.3826 10.8279L72.9402 9.88786Z"
      fill="#A03B54"
    />
    <path
      d="M76.6746 0.0211414C77.2126 0.0211414 77.6488 0.437622 77.6488 0.951375V4.50317H79.3979C79.9359 4.50317 80.3721 4.91965 80.3721 5.4334V6.32135C80.3721 6.83511 79.9359 7.25159 79.3979 7.25159H77.6488V11.1205C77.6488 11.6343 77.2126 12.0507 76.6746 12.0507H75.1894C74.6514 12.0507 74.2152 11.6343 74.2152 11.1205V0.951374C74.2152 0.437621 74.6514 0.0211414 75.1894 0.0211414H76.6746Z"
      fill="#A03B54"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.1353 1.37421C24.5039 1.37421 23.0835 2.07714 22.0774 3.33108C21.0746 4.58093 20.4898 6.36815 20.4898 8.54123C20.4898 10.7246 21.0745 12.5222 22.077 13.7799C23.0827 15.0417 24.5031 15.7505 26.1353 15.7505C27.7674 15.7505 29.1878 15.0417 30.1936 13.7799C31.196 12.5222 31.7807 10.7246 31.7807 8.54123C31.7807 6.36815 31.1959 4.58093 30.1931 3.33108C29.187 2.07714 27.7667 1.37421 26.1353 1.37421ZM26.1353 4.35518C26.7914 4.35518 27.3601 4.68261 27.7772 5.37025C28.1997 6.06675 28.4587 7.12573 28.4587 8.54123C28.4587 9.96754 28.1996 11.0319 27.777 11.731C27.3599 12.4211 26.7912 12.7484 26.1353 12.7484C25.4793 12.7484 24.9106 12.4211 24.4935 11.731C24.0709 11.0319 23.8118 9.96754 23.8118 8.54123C23.8118 7.12573 24.0708 6.06675 24.4933 5.37025C24.9104 4.68261 25.4791 4.35518 26.1353 4.35518Z"
      fill="#A03B54"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.6864 11.9239C68.5206 11.9239 66.7326 12.281 65.4768 12.961C64.2113 13.6463 63.4784 14.6674 63.4784 15.9619C63.4784 17.2471 64.2127 18.2575 65.4779 18.9344C66.7335 19.606 68.5211 19.9577 70.6864 19.9577C72.8407 19.9577 74.6227 19.606 75.8755 18.9343C77.1379 18.2574 77.872 17.2471 77.872 15.9619C77.872 14.6675 77.1392 13.6465 75.8766 12.9611C74.6236 12.2811 72.8411 11.9239 70.6864 11.9239ZM70.6864 17.3573C69.3793 17.3573 68.4078 17.2252 67.7718 16.9733C67.456 16.8483 67.2363 16.699 67.0963 16.5338C66.9595 16.3724 66.8896 16.1853 66.8896 15.9619C66.8896 15.7322 66.9603 15.5407 67.0974 15.3766C67.2375 15.2088 67.4572 15.0578 67.7725 14.9317C68.4081 14.6776 69.3791 14.5455 70.6864 14.5455C71.9823 14.5455 72.9476 14.6776 73.5803 14.9316C73.8943 15.0577 74.1133 15.2086 74.2532 15.3764C74.3901 15.5406 74.4608 15.7322 74.4608 15.9619C74.4608 16.1853 74.3908 16.3725 74.2543 16.534C74.1146 16.6992 73.8954 16.8485 73.5811 16.9735C72.9479 17.2252 71.9821 17.3573 70.6864 17.3573Z"
      fill="#A03B54"
    />
  </svg>
);
export default KsdLogo;
