import * as types from '../actions/actionTypes';

const initialState = {};

const order = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INITIAL_DATA: {
      return {
        ...state,
        id: action.data.id, // orderId
        hashKey: action.data.hashKey,
        init: action.onSnapshot ? state.init : action.data, // 초기 데이터 저장
        action: action.data.action,
        status: action.data.status,
        memos: action.data.memos,
        services: action.formattedData, // 서비스 상세
        initServices: action.formattedData, // 초기 services 데이터 저장 (소장님 추가 모달 오픈시 상세 내용 업데이트 여부 확인하기 위함)
        onSnapshot: action.onSnapshot, // 예약변경 히스토리 화면 여부
        isUpdated: false, // 서비스 상세 데이터 업데이트 여부 (저장버튼, 청구서 재전송 버튼 활성화)
        options: action.data.options, // 추가 비용 옵션
      };
    }
    case types.UPDATE_STATUS: {
      let saved = {};

      // 변경된 서비스 상세 내용 저장시
      if (
        action.action === 'requestModify' ||
        action.action === 'match' ||
        action.action === 'matchModify' ||
        action.action === 'reportModify'
      ) {
        saved = { isUpdated: false };
      }

      return {
        ...state,
        status: action.status,
        action: action.action,
        ...saved,
      };
    }
    case types.UPDATE_PRODUCT_PARENT_NAME: {
      const newServices = state.services.map((service) => {
        if (service.id === action.serviceId) {
          const newProducts = service.products.map((product) => {
            if (product.id === action.productId) {
              return {
                ...product,
                parentId: action.selectedList.id,
                parentName: action.selectedList.name,
                productTraverse: action.productTraverse,
              };
            } else {
              return product;
            }
          });
          return { ...service, products: newProducts };
        }
        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_PRODUCT: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          const newProducts = service.products.map((product) => {
            if (product.id === action.productId) {
              return {
                ...product,
                product: action.selected,
                unit: 1, // 빈값이면 결제금액정보 총 결제금액 계산에 에러남.
                productTraverse: action.productTraverse,
              };
            } else {
              c;
              return product;
            }
          });

          return { ...service, products: newProducts };
        }
        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_PRODUCT_UNIT: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          const newData = service.products.map((product) => {
            if (product.id === action.productId) {
              return { ...product, unit: action.value };
            }

            return product;
          });

          return { ...service, products: newData };
        }
        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_BOOLEAN: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          return { ...service, [action.key]: !service[action.key] };
        }
        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_OPTION: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          const newData = service.options.map((option, index) => {
            if (index === Number(action.index)) {
              if (action.key === 'name') {
                const updated = {
                  [action.key]: action.value,
                };

                return {
                  ...option,
                  ...updated,
                };
              }

              if (action.key === 'price') {
                const updated = {
                  [action.key]: Number(action.value),
                };

                return {
                  ...option,
                  ...updated,
                };
              }
            }
            return option;
          });

          return { ...service, options: newData };
        }

        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_PART: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          const newData = service.parts.map((part, index) => {
            if (index === Number(action.index)) {
              if (action.key === 'unit') {
                const updated = {
                  [action.key]: Number(action.value),
                };

                return {
                  ...part,
                  ...updated,
                };
              } else if (action.key === 'price') {
                const updated = {
                  [action.key]:
                    !!Number(action.value) || Number(action.value) == 0 ? Number(action.value) : part[action.key],
                };

                return {
                  ...part,
                  ...updated,
                };
              } else {
                return { ...part, part: { ...part.part, ...action.value } };
              }
            }

            return part;
          });
          return { ...service, parts: newData };
        }

        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.DELETE_DATA_IN_ARRAY: {
      const newServices = state.services.map((service) => {
        if (service.id === action.serviceId) {
          service[action.key].splice(action.index, 1);

          return { ...service, [action.key]: service[action.key] };
        }

        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.CREATE_DATA_IN_ARRAY: {
      const newServices = state.services.map((service) => {
        if (service.id === action.orderId) {
          return { ...service, [action.key]: [...service[action.key], action.value] };
        }

        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.CREATE_SCHEDULE: {
      const newServices = state.services.map((service) => {
        if (service.id === action.serviceId) {
          return { ...service, ...action.data };
        }
        return service;
      });

      return {
        ...state,
        init: { ...state.init, ...action.data },
        services: newServices,
      };
    }
    case types.UPDATE_SCHEDULE: {
      const newServices = state.services.map((service) => {
        if (service.id === action.serviceId) {
          if (action.key == 'assignedTeches') {
            const updated = service.assignedTeches ? [...service.assignedTeches, action.value] : [action.value];
            return { ...service, assignedTeches: updated };
          } else {
            // action.key === 'schedule'
            return { ...service, schedule: { ...service.schedule, ...action.value } };
          }
        }

        return service;
      });
      return { ...state, services: newServices, isUpdated: true };
    }
    case types.DELETE_SCHEDULE: {
      const newServices = state.services.map((service) => {
        if (service.id === action.serviceId) {
          const newData = service.assignedTeches.filter((tech, index) => index !== action.index);
          // const newData = service.assignedTeches.splice(action.index, 1); // splice's not working properly in iteration
          return { ...service, assignedTeches: newData };
        }
        return service;
      });

      return { ...state, services: newServices, isUpdated: true };
    }
    case types.UPDATE_DATA:
      return {
        ...state,
        [action.key]: action.value,
      };
    case types.RESET_ORDER:
      return {};
    default:
      return state;
  }
};

export default order;
