import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'grba(0, 0, 0, 0.1)',
    },
    message: {
      fontSize: '1rem',
      marginTop: spacing(5),
    },
  })
);

export default function CircularIndeterminate({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" />
      {message && <p className={classes.message}>{message}</p>}
    </div>
  );
}
