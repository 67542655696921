import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import * as alertActions from '../../store/actions/alertAction';
import SvgIcon from '../../assets/icons';
import theme from '../../styles/theme';

const color = {
  success: theme.palette.success.main,
  warning: theme.palette.warning.main,
  error: theme.palette.error.main,
  info: theme.palette.info.main,
};

const styles = (theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  container: {
    margin: theme.spacing(1),
    borderRadius: '0px',
  },
  messageBox: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    opacity: 0.8,
    marginRight: '10px',
  },
  message: {
    marginBottom: '-3px',
    marginLeft: '10px',
  },
});

type Props = {
  alert: {
    onAlert: boolean;
    severity: 'error' | 'warning' | 'info' | 'success' | undefined;
    text: string;
  };
  alertActions;
  classes: {
    container: string;
    messageBox: string;
    message: string;
  };
};

const WrappedAlert = ({ alert: { onAlert, severity, text }, alertActions, classes }: Props) => {
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    alertActions.removeAlert();
  };

  return (
    <Snackbar
      open={onAlert}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <SnackbarContent
        className={`${classes[severity]} ${classes.container} ${severity}`}
        aria-describedby="alert-msg"
        message={
          <div id="alert-msg" className={classes.messageBox}>
            <SvgIcon
              name={severity}
              width="16"
              height="16"
              opacity="0.8"
              viewBox="0 0 20 20"
              fillOuter="#ffffff"
              fillInner={color[severity]}
            />
            <span className={classes.message}>{text}</span>
          </div>
        }
      />
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertActions: bindActionCreators(alertActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WrappedAlert));
