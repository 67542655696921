import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	triangleBottom: ({ disabled, width, top }: any) => ({
		width: 0,
		height: 0,
		borderLeft: `${width || '7px'} solid transparent`,
		borderRight: `${width || '7px'} solid transparent`,
		borderTop: `${width || '8px'} solid ${disabled ? theme.palette.grey[400] : theme.palette.common.black}`,
		position: 'absolute',
		top: top || '8px',
		right: '0px',
	}),
}));

type Props = {
	type: 'bottom';
	disabled?: boolean;
	width?: string | null;
	top?: string | null;
};

const Arrow = ({ type, disabled, width, top }: Props) => {
	const classes = useStyles({ disabled, width, top });
	return <>{type === 'bottom' && <div className={classes.triangleBottom} />}</>;
};

Arrow.defaultProps = {
	disabled: false,
	width: null,
	top: null,
};

export default Arrow;
