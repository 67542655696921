import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducers';

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  middlewares.push(
    createLogger({
      predicate: () => true,
      collapsed: true,
    })
  );
}

const enhancers = [applyMiddleware(...middlewares)];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['order', 'modal', 'multiData', 'list'],
};

const enhancedReducer = persistReducer(persistConfig, rootReducer);

export default (initialState) => {
  const store = createStore(enhancedReducer, initialState, composeWithDevTools(...enhancers));
  const persistor = persistStore(store);

  return { store, persistor };
};
