import * as types from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  data: null,
  status: null,
  error: false,
  api: null,
};

const asyncApi = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: false,
        api: action.api || null,
      };
    case types.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data || null,
        error: false,
        api: action.api || null,
      };
    case types.FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        status: action.status,
        error: action.error,
        api: action.api || null,
      };
    default:
      return state;
  }
};

export default asyncApi;
