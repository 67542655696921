module.exports = {
  // route
  UPDATE_PATH: 'UPDATE_PATH',
  // alert
  ON_ALERT: 'ON_ALERT',
  OFF_ALERT: 'OFF_ALERT',
  // modal
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  // async
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  // order
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_PRODUCT_PARENT_NAME: 'UPDATE_PRODUCT_PARENT_NAME',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_UNIT: 'UPDATE_PRODUCT_UNIT',
  UPDATE_BOOLEAN: 'UPDATE_BOOLEAN',
  UPDATE_OPTION: 'UPDATE_OPTION',
  UPDATE_PART: 'UPDATE_PART',
  DELETE_DATA_IN_ARRAY: 'DELETE_DATA_IN_ARRAY',
  CREATE_DATA_IN_ARRAY: 'CREATE_DATA_IN_ARRAY',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  RESET_ORDER: 'RESET_ORDER',
  UPDATE_DATA: 'UPDATE_DATA',
  // product
  SET_CHARGE_PRODUCTS: 'SET_CHARGE_PRODUCTS',
  // multi data
  SAVE_KEY_VALUE_DATA: 'SAVE_KEY_VALUE_DATA',
  SAVE_OBJECT_DATA: 'SAVE_OBJECT_DATA',
};
