import React from 'react';

const Products = ({ name, width, height, viewBox, fill }) => (
  <svg
    width={width || '19'}
    height={height || '18'}
    viewBox={viewBox || '0 0 19 18'}
    className={`svg-icon icon-${name}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M15.8333 2.50016H12.35C12 1.5335 11.0833 0.833496 10 0.833496C8.91667 0.833496 8 1.5335 7.65 2.50016H4.16667C3.25 2.50016 2.5 3.25016 2.5 4.16683V15.8335C2.5 16.7502 3.25 17.5002 4.16667 17.5002H15.8333C16.75 17.5002 17.5 16.7502 17.5 15.8335V4.16683C17.5 3.25016 16.75 2.50016 15.8333 2.50016ZM10 2.50016C10.4583 2.50016 10.8333 2.87516 10.8333 3.3335C10.8333 3.79183 10.4583 4.16683 10 4.16683C9.54167 4.16683 9.16667 3.79183 9.16667 3.3335C9.16667 2.87516 9.54167 2.50016 10 2.50016ZM11.6667 14.1668H5.83333V12.5002H11.6667V14.1668ZM14.1667 10.8335H5.83333V9.16683H14.1667V10.8335ZM14.1667 7.50016H5.83333V5.8335H14.1667V7.50016Z"
      fill={fill || '#757883'}
    />
  </svg>
);

export default Products;
