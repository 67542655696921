import React from 'react';

const Menu = ({ name, width, height, viewBox, fill }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox={viewBox || '0 0 24 24'}
    className={`svg-icon icon-${name}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 7C21 7.55228 20.5523 8 20 8L4 8C3.44772 8 3 7.55228 3 7C3 6.44771 3.44772 6 4 6L20 6C20.5523 6 21 6.44772 21 7Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 12.5523 20.5523 13 20 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L20 11C20.5523 11 21 11.4477 21 12Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 17C21 17.5523 20.5523 18 20 18L4 18C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16L20 16C20.5523 16 21 16.4477 21 17Z"
      fill={fill}
    />
  </svg>
);
export default Menu;
