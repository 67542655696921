/* eslint-disable no-useless-return */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { showAlert } from '../store/actions/alertAction';
import { isLoggedIn, clearStorage } from '../helpers/storage';
import * as messages from '../helpers/messages';

const PrivateRoute = ({ component: Component, onShowAlert, ...rest }) => {
  if (!isLoggedIn()) {
    onShowAlert('warning', messages.auth.required);
    clearStorage();
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!isLoggedIn() ? (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  onShowAlert: (severity, text) => dispatch(showAlert(severity, text)),
});
export default connect(null, mapDispatchToProps)(PrivateRoute);
