import React from 'react';

const System = ({ name, width, height, viewBox, fill }) => (
  <svg
    width={width || '24'}
    height={height || '18'}
    viewBox={viewBox || '0 0 24 24'}
    className={`svg-icon icon-${name}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z"
      fill={fill || '#757883'}
    />
  </svg>
);

export default System;
