import React from 'react';

const Check = ({ name, width, height, viewBox, fill = 'white' }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox={viewBox || '0 0 24 24'}
    className={`svg-icon icon-${name}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99991 16.1698L4.82991 11.9998L3.40991 13.4098L8.99991 18.9998L20.9999 6.99984L19.5899 5.58984L8.99991 16.1698Z"
      fill={fill}
    />
  </svg>
);
export default Check;
