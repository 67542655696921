import { combineReducers } from 'redux';
/* --- Components --- */
import route from './reducers/routeReducer';
import alert from './reducers/alertReducer';
import asyncApi from './reducers/asyncReducer';
import order from './reducers/orderReducer';
import modal from './reducers/modalReducer';
import list from './reducers/listReducer';
import multiData from './reducers/multiDataReducer';

export default combineReducers({
  route,
  alert,
  asyncApi,
  order,
  modal,
  list,
  multiData,
});
