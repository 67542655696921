import React from 'react';

const Close = ({ name, width, height, viewBox, fill }) => (
	<svg
		width={width || '24'}
		height={height || '24'}
		viewBox={viewBox || '0 0 24 24'}
		className={`svg-icon icon-${name}`}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
			fill={fill}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L4.7071 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
			fill={fill}
		/>
	</svg>
);
export default Close;
