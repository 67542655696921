import * as types from './actionTypes';

export const showAlert = (severity, text) => ({
  type: types.ON_ALERT,
  severity,
  text,
});

export const removeAlert = () => ({
  type: types.OFF_ALERT,
});
