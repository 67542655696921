// 빌드시 초기 환경
let api = process.env.NODE_ENV === 'development' ? process.env.API_HOST_STRAPI_DEV : process.env.API_HOST_STRAPI_PROD;

// 사용자가 선택한 환경
if (sessionStorage['env']) {
  api = sessionStorage['env'] === 'dev' ? process.env.API_HOST_STRAPI_DEV : process.env.API_HOST_STRAPI_PROD;
}
const apis = {
  ngrok: 'https://81cdda7f5a2c.ngrok.io',
  local: 'http://localhost:1337',
  api,
};

export const API_HOST = apis.api;
