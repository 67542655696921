import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from './index';

const useStyles = makeStyles(({ palette, typography }) => ({
  icon: ({ type, code: { id, code, level }, margin, color, borderRadius }: any) => {
    let setBackColor = color || palette.primary.main;
    let setColor = palette.common.white;

    if (type === 'category') {
      if (level == null) {
        setBackColor = palette.grey[200];
        setColor = palette.grey[500];
      } else if (level === 'experienced') {
        setBackColor = palette.common.black;
      } else if (level === 'expert') {
        setBackColor = palette.primary.main;
      }
    }

    if (type === 'orderStatus') {
      setBackColor = palette.warning.main; // orange

      if (code === 'requested' || code === 'confirmed') {
        setBackColor = palette.info.main; // blue
      } else if (code === 'canceled' || code === 'hold') {
        setBackColor = palette.error.main; // red
      } else if (code === 'paid') {
        setBackColor = palette.success.main; // green
      } else if (code === 'manualPaid' || code === 'refunded' || code === 'etc') {
        setBackColor = '#7B61FF'; // purple
      }
    }

    if (type === 'eventStatus') {
      setBackColor = palette.warning.main;

      if (id === 'onGoing') {
        setBackColor = palette.success.main;
      } else if (id === 'done') {
        setBackColor = palette.common.black;
      }
    }

    return {
      ...typography.body2,
      border: `1px solid ${setBackColor}`,
      background: setBackColor,
      color: setColor,
      borderRadius: borderRadius || '100px',
      textAlign: 'center',
      display: 'inline-block',
      margin: margin || 0,
      fontWeight: 'bold',
    };
  },
  textIcon: ({ padding, whiteSpace }: any) => ({
    position: 'relative',
    padding: padding || '3px 12px 1px',
    whiteSpace: whiteSpace || 'inherit',
  }),
  circle: {
    background: palette.error.main,
    width: 5,
    height: 5,
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '-10px',
  },
  zImage: {
    padding: '3px 12px 0px',
  },
}));

const useTooltipStyles = makeStyles(({ palette, shadows, typography }) => ({
  arrow: {
    color: palette.common.white,
  },
  tooltip: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    boxShadow: shadows[1],
    ...typography.caption,
  },
}));

type Props = {
  type: 'category' | 'orderStatus' | 'eventStatus' | 'custom'; // 수리가능 분야 상태 | 예약 진행 상태 | 이벤트 진행 상태 | 커스텀 태그
  code: { code?: number | string; name: string; memo?: string; level?: number };
  styles: { margin?: string; padding?: string; width?: string; top?: string; whiteSpace?: string };
};

const TextIcon = ({ type, code, styles }: Props) => {
  const classes = useStyles({ ...styles, type, code });
  const tooltipClasses = useTooltipStyles();

  return (
    <Tooltip title={code?.memo || ''} placement="right-end" arrow classes={tooltipClasses}>
      {type === 'orderStatus' && code?.code === 'zService' ? (
        <div className={`${classes.icon} ${classes.zImage}`}>
          <Icon name="zService" />
        </div>
      ) : (
        <div className={`${classes.icon} ${classes.textIcon}`}>
          {code?.name || (typeof code === 'object' ? '' : String(code))}
          {type === 'orderStatus' && code.code === 'getReady' ? <span className={classes.circle}></span> : null}
        </div>
      )}
    </Tooltip>
  );
};

TextIcon.defaultProps = {
  type: 'orderStatus',
};

export default TextIcon;
