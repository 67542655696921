import React, { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { history } from '../helpers/history';
import PrivateRoute from './privateRoute';
import ErrorBoundary from '../helpers/errorBoundary';
import Loading from '../helpers/loading';
import App from '../app';

const Login = lazy(() => import('../components/auth/main'));
const OrdersMain = lazy(() => import('../components/orders/ordersMain'));
const OrderMain = lazy(() => import('../components/orders/orderMain'));
const ScheduleMain = lazy(() => import('../components/schedule/scheduleMain'));
const ManagersMain = lazy(() => import('../components/managers/managersMain'));
const ManagerMain = lazy(() => import('../components/managers/managerMain'));
const EventsMain = lazy(() => import('../components/events/eventsMain'));
const EventMain = lazy(() => import('../components/events/eventMain'));
const ProductsMain = lazy(() => import('../components/retool/productsTool'));
const PartsMain = lazy(() => import('../components/retool/partsTool'));
const SystemMain = lazy(() => import('../components/retool/systemTool'));
const NotFound = lazy(() => import('../helpers/notFound'));

const routes = () => {
  const model = {
    disabled: true,
    checked: [],
  };

  return (
    <Router history={history}>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <App />
          <Switch>
            <Route exact path={['/', '/login']} component={Login} />
            <PrivateRoute exact path={['/orders']} component={OrdersMain} />
            <PrivateRoute path="/orders/:hashKey" component={OrderMain} />
            <PrivateRoute path="/schedules" component={ScheduleMain} />
            <PrivateRoute exact path="/managers" component={ManagersMain} />
            <PrivateRoute exact path="/managers/:id" component={ManagerMain} />
            <PrivateRoute exact path="/manager" component={ManagerMain} />
            <PrivateRoute exact path="/events" component={EventsMain} />
            <PrivateRoute path="/events/:id" component={EventMain} />
            <PrivateRoute path="/event" component={EventMain} />
            <PrivateRoute exact path="/products" component={ProductsMain} />
            <PrivateRoute exact path="/parts" component={PartsMain} />
            <PrivateRoute exact path="/system" component={SystemMain} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

const Routes = module.hot ? hot(routes) : routes;

export default Routes;
