import React from 'react';

const Orders = ({ name, width, height, viewBox, fill }) => (
	<svg
		width={width || '27'}
		height={height || '11'}
		viewBox={viewBox || '0 0 17 11'}
		className={`svg-icon icon-${name}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.85791 2.66667H12.5725V8H3.85791V2.66667ZM14.7512 5.33333C14.7512 6.06972 15.3364 6.66667 16.0583 6.66667V9.33333C16.0583 10.0697 15.4731 10.6667 14.7512 10.6667H1.67926C0.95731 10.6667 0.37207 10.0697 0.37207 9.33333V6.66667C1.09402 6.66667 1.67926 6.06972 1.67926 5.33333C1.67926 4.59694 1.09402 4 0.37207 4V1.33333C0.37207 0.596944 0.95731 0 1.67926 0H14.7512C15.4731 0 16.0583 0.596944 16.0583 1.33333V4C15.3364 4 14.7512 4.59694 14.7512 5.33333ZM13.444 2.44444C13.444 2.07625 13.1513 1.77778 12.7904 1.77778H3.64004C3.27907 1.77778 2.98645 2.07625 2.98645 2.44444V8.22222C2.98645 8.59041 3.27907 8.88889 3.64004 8.88889H12.7904C13.1513 8.88889 13.444 8.59041 13.444 8.22222V2.44444Z"
			fill={fill}
		/>
	</svg>
);
export default Orders;
