import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: ({ position: { top, ...rest }, padding }: any) => ({
    padding: padding || '20px',
    position: 'absolute',
    ...rest,
    top: `calc(${top} - ${padding || '20px'})`,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    zIndex: 9,
  }),
  arrow: ({ width, size, disabled }: any) => ({
    border: `1px solid ${disabled ? theme.palette.grey[400] : theme.palette.common.black}`,
    borderWidth: width ? `0 ${width} ${width} 0` : '0 2px 2px 0',
    display: 'inline-block',
    padding: size || 5,
    margin: '0 auto',
  }),
  right: {
    transform: 'rotate(-45deg)',
    webkitTransform: 'rotate(-45deg)',
    mozTransform: 'rotate(-45deg)',
  },
  left: {
    transform: 'rotate(135deg)',
    webkitTransform: 'rotate(135deg)',
    mozTransform: 'rotate(135deg)',
  },
  up: {
    transform: 'rotate(-135deg)',
    webkitTransform: 'rotate(-135deg)',
    mozTransform: 'rotate(-135deg)',
  },
  down: {
    transform: 'rotate(45deg)',
    webkitTransform: 'rotate(45deg)',
    mozTransform: 'rotate(45deg)',
  },
}));

type Props = {
  type: 'left' | 'right' | 'up' | 'down';
  width?: string | null;
  size?: number | null;
  position?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  padding?: string;
  onClick: () => {};
  disabled?: boolean;
};

const Arrow = ({ type, width, size, position, padding, onClick, disabled }: Props) => {
  const classes = useStyles({ width, size, position, padding, disabled });

  return (
    <>
      {
        <button type="button" onClick={onClick} className={classes.button}>
          <div className={`${classes.arrow} ${classes[type]}`} />
        </button>
      }
    </>
  );
};

Arrow.defaultProps = {
  width: null,
  size: null,
  disabled: false,
};

export default Arrow;
