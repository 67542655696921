import * as types from '../actions/actionTypes';

const initialState = {
  charges: [],
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CHARGE_PRODUCTS:
      return {
        ...state,
        charges: action.charges,
      };
    default:
      return state;
  }
};

export default products;
