import React from 'react';
import { render } from 'react-dom';
// route
import Routes from './routes/routes';
// redux
import { Provider } from 'react-redux';
import configureStore from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
// axios
import { interceptors } from './store/actions/axios';
// etc
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './styles/theme';
import DateFnsUtils from '@date-io/date-fns';
import { ko } from 'date-fns/esm/locale';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// component
import Loading from './helpers/loading';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()], // react 설정
    tracingOptions: {
      // 하위 구성 요소를 추적하고 렌더링 프로세스에 대한 자세한 내용을 보기
      trackComponents: true,
    },
    attachProps: true, // 로깅을 위해 모든 Vue 구성 요소의 props를 보기
    tracesSampleRate: 1, // 0에서 1 사이의 숫자로 주어진 트랜잭션이 Sentry로 전송 될 확률을 제어
  });
}

const initialState = {};
const { store, persistor } = configureStore(initialState);

// axios interceptors에 store 도입
interceptors(store);
const muiTheme = createMuiTheme(theme);

const root = document.createElement('div');
document.body.appendChild(root);

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CssBaseline />
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  root
);
