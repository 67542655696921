import React from 'react';

const Managers = ({ name, width, height, viewBox, fill }) => (
  <svg
    width={width || '17'}
    height={height || '13'}
    viewBox={viewBox || '0 0 17 12'}
    className={`svg-icon icon-${name}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72501 5.1999C3.59021 5.1999 4.29364 4.4824 4.29364 3.5999C4.29364 2.7174 3.59021 1.9999 2.72501 1.9999C1.85982 1.9999 1.15638 2.7174 1.15638 3.5999C1.15638 4.4824 1.85982 5.1999 2.72501 5.1999ZM13.7054 5.1999C14.5706 5.1999 15.274 4.4824 15.274 3.5999C15.274 2.7174 14.5706 1.9999 13.7054 1.9999C12.8402 1.9999 12.1368 2.7174 12.1368 3.5999C12.1368 4.4824 12.8402 5.1999 13.7054 5.1999ZM14.4897 5.9999H12.9211C12.4897 5.9999 12.1 6.1774 11.8157 6.4649C12.8034 7.0174 13.5044 8.0149 13.6564 9.1999H15.274C15.7079 9.1999 16.0583 8.8424 16.0583 8.3999V7.5999C16.0583 6.7174 15.3549 5.9999 14.4897 5.9999ZM8.21521 5.9999C9.73236 5.9999 10.9603 4.7474 10.9603 3.1999C10.9603 1.6524 9.73236 0.399902 8.21521 0.399902C6.69805 0.399902 5.47011 1.6524 5.47011 3.1999C5.47011 4.7474 6.69805 5.9999 8.21521 5.9999ZM10.0976 6.7999H9.89413C9.38432 7.0499 8.81815 7.1999 8.21521 7.1999C7.61226 7.1999 7.04854 7.0499 6.53628 6.7999H6.33285C4.77403 6.7999 3.50932 8.0899 3.50932 9.6799V10.3999C3.50932 11.0624 4.03629 11.5999 4.68579 11.5999H11.7446C12.3941 11.5999 12.9211 11.0624 12.9211 10.3999V9.6799C12.9211 8.0899 11.6564 6.7999 10.0976 6.7999ZM4.61472 6.4649C4.3304 6.1774 3.9407 5.9999 3.50932 5.9999H1.9407C1.0755 5.9999 0.37207 6.7174 0.37207 7.5999V8.3999C0.37207 8.8424 0.72256 9.1999 1.15638 9.1999H2.77158C2.92599 8.0149 3.62697 7.0174 4.61472 6.4649Z"
      fill={fill}
    />
  </svg>
);
export default Managers;
