import React from 'react';

const User = ({ name, width, height, viewBox, fill }) => (
	<svg
		width={width || '58'}
		height={height || '58'}
		viewBox={viewBox || '0 0 58 58'}
		className={`svg-icon icon-${name}`}
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M29 24.2C32.9765 24.2 36.2 20.9765 36.2 17C36.2 13.0236 32.9765 9.8 29 9.8C25.0236 9.8 21.8 13.0236 21.8 17C21.8 20.9765 25.0236 24.2 29 24.2ZM29 29C35.6274 29 41 23.6274 41 17C41 10.3726 35.6274 5 29 5C22.3726 5 17 10.3726 17 17C17 23.6274 22.3726 29 29 29Z"
			fill={fill || '#BBBEC6'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.718 48.1999C13.0985 46.84 13.7304 45.1384 14.7624 43.4533C16.953 39.8759 21.023 36.1999 28.9998 36.1999C36.9767 36.1999 41.0466 39.8759 43.2373 43.4533C44.2692 45.1384 44.9012 46.84 45.2816 48.1999H12.718ZM48.1998 50.5999C50.5918 50.4041 50.5917 50.4028 50.5916 50.4014L50.5914 50.3984L50.5907 50.3911L50.589 50.3719L50.5837 50.3153C50.5791 50.2696 50.5727 50.2081 50.5637 50.1318C50.5458 49.9792 50.5181 49.7673 50.4766 49.5043C50.3938 48.979 50.2556 48.246 50.0299 47.3738C49.5809 45.6381 48.7732 43.3021 47.3308 40.9466C44.3776 36.1239 38.8475 31.3999 28.9998 31.3999C19.1522 31.3999 13.6221 36.1239 10.6689 40.9466C9.22644 43.3021 8.4188 45.6381 7.96976 47.3738C7.74412 48.246 7.60585 48.979 7.52304 49.5043C7.48157 49.7673 7.45385 49.9792 7.43596 50.1318C7.42702 50.2081 7.42053 50.2696 7.416 50.3153L7.41063 50.3719L7.40893 50.3911L7.40832 50.3984L7.40806 50.4014C7.40795 50.4028 7.40784 50.4041 9.79984 50.5999L7.40784 50.4041L7.19531 52.9999H50.8044L50.5918 50.4041L48.1998 50.5999Z"
			fill={fill || '#BBBEC6'}
		/>
	</svg>
);

export default User;
