import * as types from '../actions/actionTypes';

const initialState = {
  drawer: true,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        [action.code]: true,
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        [action.code]: false,
      };

    default:
      return state;
  }
};

export default modal;
