import React from 'react';

const Parts = ({ name, width, height, viewBox, fill }) => (
  <svg
    width={width || '19'}
    height={height || '18'}
    viewBox={viewBox || '0 0 19 18'}
    className={`svg-icon icon-${name}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M16.666 4.99984H14.166V3.33317C14.166 2.40817 13.4243 1.6665 12.4993 1.6665H7.49935C6.57435 1.6665 5.83268 2.40817 5.83268 3.33317V4.99984H3.33268C2.40768 4.99984 1.66602 5.7415 1.66602 6.6665V15.8332C1.66602 16.7582 2.40768 17.4998 3.33268 17.4998H16.666C17.591 17.4998 18.3327 16.7582 18.3327 15.8332V6.6665C18.3327 5.7415 17.591 4.99984 16.666 4.99984ZM7.49935 3.33317H12.4993V4.99984H7.49935V3.33317ZM16.666 15.8332H3.33268V14.1665H16.666V15.8332ZM16.666 11.6665H3.33268V6.6665H5.83268V8.33317H7.49935V6.6665H12.4993V8.33317H14.166V6.6665H16.666V11.6665Z"
      fill={fill || '#757883'}
    />
  </svg>
);

export default Parts;
