import React from 'react';

const Events = ({ name, width, height, fill, viewBox }) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox={viewBox || '0 0 20 20'}
    className={`svg-icon icon-${name}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.666 4.99984H14.8493C14.941 4.7415 14.9993 4.45817 14.9993 4.1665C14.9993 2.78317 13.8827 1.6665 12.4993 1.6665C11.6243 1.6665 10.866 2.1165 10.416 2.7915L9.99935 3.34984L9.58268 2.78317C9.13268 2.1165 8.37435 1.6665 7.49935 1.6665C6.11602 1.6665 4.99935 2.78317 4.99935 4.1665C4.99935 4.45817 5.05768 4.7415 5.14935 4.99984H3.33268C2.40768 4.99984 1.67435 5.7415 1.67435 6.6665L1.66602 15.8332C1.66602 16.7582 2.40768 17.4998 3.33268 17.4998H16.666C17.591 17.4998 18.3327 16.7582 18.3327 15.8332V6.6665C18.3327 5.7415 17.591 4.99984 16.666 4.99984ZM12.4993 3.33317C12.9577 3.33317 13.3327 3.70817 13.3327 4.1665C13.3327 4.62484 12.9577 4.99984 12.4993 4.99984C12.041 4.99984 11.666 4.62484 11.666 4.1665C11.666 3.70817 12.041 3.33317 12.4993 3.33317ZM7.49935 3.33317C7.95768 3.33317 8.33268 3.70817 8.33268 4.1665C8.33268 4.62484 7.95768 4.99984 7.49935 4.99984C7.04102 4.99984 6.66602 4.62484 6.66602 4.1665C6.66602 3.70817 7.04102 3.33317 7.49935 3.33317ZM16.666 15.8332H3.33268V14.1665H16.666V15.8332ZM16.666 11.6665H3.33268V6.6665H7.56602L5.83268 9.02484L7.18268 9.99984L9.16602 7.29984L9.99935 6.1665L10.8327 7.29984L12.816 9.99984L14.166 9.02484L12.4327 6.6665H16.666V11.6665Z"
      fill={fill}
    />
  </svg>
);
export default Events;
