export default {
  palette: {
    common: { black: '#333333', white: '#ffffff' },
    primary: { main: '#A03B54', light: '#DCA9B6', dark: '#693744' },
    secondary: { main: '#FAF7F2' },
    error: { main: '#FF5757' }, // red
    success: { main: '#43A047' }, // green
    warning: { main: '#FF9800' }, // orange
    info: { main: '#0065DC' }, // blue
    grey: {
      100: '#F9FAFC',
      200: '#F3F5F9',
      300: '#DADEE4',
      400: '#BBBEC6', // disabled
      500: '#757883',
      800: 'rgba(117, 120, 131, 0.1)', // grey w/ opacity
      900: 'rgba(160, 59, 84, 0.1)', // primary w/ opacity
    },
  },
  typography: {
    fontFamily: 'Spoqa Han Sans Neo',
    h1: { fontSize: 40, lineHeight: '56px', fontWeight: 'bold' },
    h2: { fontSize: 32, lineHeight: '48px', fontWeight: 'bold' },
    h3: { fontSize: 24, lineHeight: '32px', fontWeight: 'bold' },
    h4: { fontSize: 20, lineHeight: '28px', fontWeight: 'bold' },
    h5: { fontSize: 18, lineHeight: '24px', fontWeight: 'bold' },
    body1: { fontSize: 16, lineHeight: '24px' },
    body2: { fontSize: 14, lineHeight: '22px' },
    caption: { fontSize: 12, lineHeight: '20px' },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#333333',
        },
      },
    },
  },
};
