import React from 'react';

/* --- SVG Icon Components --- */
import Error from './error';
import Info from './info';
import Success from './success';
import Warning from './warning';
import KsdLogo from './ksdLogo';
import Menu from './menu';
import Close from './close';
import Orders from './orders';
import Managers from './managers';
import User from './user';
import Search from './search';
import Events from './events';
import Zservice from './zService';
import Refresh from './refresh';
import System from './system';
import Products from './products';
import Parts from './parts';
import Rotate from './rotate';
import Check from './check';
import Image from './image';
import Schedules from './schedules';
/* --- CSS Icon Components --- */
import FilledArrow from './filledArrow';
import Arrow from './arrow';
import TextIcon from './textIcon';

const SvgIcon = (props) => {
  switch (props.name) {
    case 'ksdLogo':
      return <KsdLogo {...props} />;
    case 'error':
      return <Error {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'success':
      return <Success {...props} />;
    case 'warning':
      return <Warning {...props} />;
    case 'menu':
      return <Menu {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'orders':
      return <Orders {...props} />;
    case 'managers':
      return <Managers {...props} />;
    case 'user':
      return <User {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'events':
      return <Events {...props} />;
    case 'zService':
      return <Zservice {...props} />;
    case 'refresh':
      return <Refresh {...props} />;
    case 'products':
      return <Products {...props} />;
    case 'parts':
      return <Parts {...props} />;
    case 'system':
      return <System {...props} />;
    case 'rotate':
      return <Rotate {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'filledArrow':
      return <FilledArrow {...props} />;
    case 'arrow':
      return <Arrow {...props} />;
    case 'textIcon':
      return <TextIcon {...props} />;
    case 'schedules':
      return <Schedules {...props} />;

    default:
      return <div />;
  }
};

export default SvgIcon;
