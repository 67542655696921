import React from 'react';

const Image = ({ name, width, height, viewBox }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox={viewBox || '0 0 24 24'}
      fill="none"
      className={`svg-icon icon-${name}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 31.6667V8.33333C35 6.5 33.5 5 31.6667 5H8.33333C6.5 5 5 6.5 5 8.33333V31.6667C5 33.5 6.5 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667ZM14.1667 22.5L18.3333 27.5167L24.1667 20L31.6667 30H8.33333L14.1667 22.5Z"
        fill="#DADEE4"
      />
    </svg>
  );
};
export default Image;
