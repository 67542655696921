import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from '../components/shared/button';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.black,

      '& h1': {
        color: theme.palette.primary.main,
      },
      '& p': {
        fontSize: '1.6em',
        margin: 5,
      },
    },
  })
);

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  const onRedirect = () => {
    history.push('/');
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <h1>404. Not Found. </h1>
      <p>Oops! Something's missing...</p>
      <p>The page does not exist or is unavailable.</p>
      <Button name="Go Home" styles={{ margin: '20px 0px 0px' }} onClick={onRedirect} />
    </div>
  );
};

export default NotFound;
