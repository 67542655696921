import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

type Props = {
  id?: string;
  type: 'button' | 'submit' | 'reset';
  name: string;
  onClick?: () => void;
  disabled?: boolean;
  styles?: {
    padding?: string;
    margin?: string;
    webMargin?: string;
    width?: string;
    borderRadius?: string;
  };
  size?: 'small' | 'medium' | 'large'; // small(text btn), medium(small btn), large(big btn)
  color: 'primary' | 'outlined' | 'none'; // primary(filled with primary color), outlined(transparent), none(text button)
};

const useButtonStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  root: ({ size, color, width, padding, margin, webMargin, borderRadius }: any = {}) => {
    // [size] default : small
    let textSize = typography.body2;
    let defaultPadding = '3px 12px';

    if (size === 'medium') {
      textSize = typography.caption;
      defaultPadding = '3px 12px 2px';
    } else if (size === 'large') {
      defaultPadding = '7px 16px 8px';
    }

    // [color] default : outlined
    // active
    let activeColor = palette.primary.main;
    let activeBorder = `1px solid ${palette.primary.main}`;
    let activeBgColor = palette.common.white;
    // hover
    let hoverColor = palette.primary.dark;
    let hoverBorder = palette.primary.dark;
    let hoverBgColor = palette.grey[800];
    // disabled / default : outline x
    let disabledColor = palette.grey[400];
    let disabledBorder = palette.grey[200];
    let disabledBgColor = palette.grey[200];

    if (color === 'outlined') {
      // disabled
      disabledBorder = palette.grey[400];
      disabledBgColor = palette.common.white;
    } else if (color === 'primary') {
      // active
      activeColor = palette.common.white;
      activeBgColor = palette.primary.main;

      // hover
      hoverColor = palette.common.white;
      hoverBorder = palette.primary.dark;
      hoverBgColor = palette.primary.dark;
    } else if (color === 'none') {
      // active
      activeBorder = '1px solid transparent';
      activeBgColor = 'transparent';

      // hover
      hoverColor = palette.primary.main;
      hoverBorder = palette.grey[200];
      hoverBgColor = palette.grey[200];

      // disabled
      disabledBorder = 'none';
      disabledBgColor = 'transparent';
    }

    return {
      ...textSize,
      fontWeight: 'bold',
      borderRadius: borderRadius || (color === 'none' ? '14px' : 0),
      color: activeColor,
      border: activeBorder,
      backgroundColor: activeBgColor,
      margin: margin || 0,
      width: width || 'auto',
      padding: padding || defaultPadding,

      [breakpoints.up('lg')]: {
        margin: webMargin || margin || 0,
      },

      '&:hover': {
        backgroundColor: hoverBgColor,
        borderColor: hoverBorder,
        color: hoverColor,
        transition: 'none',
        boxShadow: 'none',
      },

      '&:disabled': {
        backgroundColor: disabledBgColor,
        borderColor: disabledBorder,
        color: disabledColor,
      },
    };
  },
  disabled: {},
  contained: {
    boxShadow: 'none',
  },
}));

const WrappedButton = ({
  id,
  type = 'button',
  name,
  onClick,
  disabled = false,
  styles,
  size = 'large',
  color = 'primary',
}: Props) => {
  const classes = useButtonStyles({ ...styles, size, color });

  return (
    <Button
      id={id}
      variant="contained"
      type={type}
      onClick={onClick}
      classes={{
        root: classes.root,
        contained: classes.contained,
        disabled: classes.disabled,
      }}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

WrappedButton.defaultProps = {
  type: 'button',
  onClick: null,
  disabled: false,
  styles: {},
  color: 'primary',
};

export default WrappedButton;
