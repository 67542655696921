import React from 'react';

const Schedules = ({ name, width, height, fill, viewBox }) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox={viewBox || '0 0 20 20'}
    className={`svg-icon icon-${name}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.666 2.50016H15.8327V0.833496H14.166V2.50016H5.83268V0.833496H4.16602V2.50016H3.33268C2.41602 2.50016 1.66602 3.25016 1.66602 4.16683V17.5002C1.66602 18.4168 2.41602 19.1668 3.33268 19.1668H16.666C17.5827 19.1668 18.3327 18.4168 18.3327 17.5002V4.16683C18.3327 3.25016 17.5827 2.50016 16.666 2.50016ZM16.666 17.5002H3.33268V6.66683H16.666V17.5002Z"
      fill={fill}
    />
  </svg>
);
export default Schedules;
